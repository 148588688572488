import RealDeal from '@public/real-deal.png'
import Forbes from '@public/forbes.png'
import Bloomberg from '@public/bloomberg.png'
import Business from '@public/business.png'
import Image from 'next/image'

export default function Publications() {
  return (
    <div className="bg-light-10 py-10">
      <div className="max-w-screen-lg mx-auto w-11/12 text-center ">
        <h3 className="text-pink-900 text-2xl font-semibold pb-10">As seen in</h3>
        <div className='grid sm:grid-cols-4 gap-20 sm:px-0 px-20'>
          <div className='m-auto'>
            <Image
              objectPosition="center"
              objectFit="cover"
              className="mx-auto"
              src={RealDeal}
              alt="landing cover"
            />
          </div>
          <div className='m-auto'>
            <Image
              objectPosition="center"
              objectFit="cover"
              className="mx-auto"
              src={Forbes}
              alt="landing cover"
            />
          </div>
          <div className='m-auto'>
            <Image
              objectPosition="center"
              objectFit="cover"
              className="mx-auto"
              src={Bloomberg}
              alt="landing cover"
            />
          </div>
          <div className='m-auto'>
            <Image
              objectPosition="center"
              objectFit="cover"
              className="mx-auto"
              src={Business}
              alt="landing cover"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
