import type { NextPage } from 'next'

import { Layout } from '@components/index'

import { useAppSelector } from '@redux/hooks'
// import { useRouter } from 'next/router'

// import { segment } from 'src/lib/analytics'
import Image from 'next/image'
import Publications from '@components/home/Publications'
import { useEffect, useState } from 'react'
import UnitImg from '@public/unit.png'
import AboutUs from '@public/aboutus-graphics.png'
import LetsConnectModal from '@components/landing/LetsConnectModal'
import Script from 'next/script'
import { useAuth } from '@stores/authContext'
// import OCG from '@public/icons/ocg.svg'
// import Link from 'next/link'
// import { BiRightArrowAlt } from 'react-icons/bi'

const Home: NextPage = () => {
  const { isLoggedIn } = useAppSelector((state) => state.currentUser)
  const [showModal, setShowModal] = useState(false)
  // const router = useRouter()

  const { logout } = useAuth()

  useEffect(() => {
    if(isLoggedIn){
      logout()
    }
  },[isLoggedIn])

  return (
    <>
      <Layout>
        <>
          {/* <div className="bg-light-10">
            <div className="max-w-screen-lg mx-auto w-11/12 bg-light-10 py-10 space-y-6">
              <div className="text-pink-900 text-2xl font-semibold text-center">
                Are You Doing Well As A Landlord?
              </div>
              <div className="rounded-xl flex overflow-hidden w-full shadow-lg">
                <div className="bg-dark-900 py-6 px-2 md:px-6 w-1/4 flex items-center md:max-w-[120px]">
                  <OCG className="w-full" />
                </div>
                <div className="flex-grow p-6 shadow-xl bg-white flex flex-col justify-between">
                  <h3 className="text-dark-900 text-lg font-semibold">
                    Portfolio Performance Review
                  </h3>
                  <Link passHref href={'/portfolio-perf-report'}>
                    <a className="flex items-center space-x-1 text-pink-600 text-sm">
                      <u>Generate my portfolio report</u>
                      <BiRightArrowAlt />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          <div className="bg-pink-900 py-10">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-20 gap-10 max-w-screen-lg mx-auto w-11/12">
              <div className="sm:my-auto">
                <div className="text-white text-4xl font-semibold">
                  Rent your apartments on openigloo
                </div>
                <div className="text-white font-base py-4">
                  Leverage your online reputation to rent to pre-vetted and verified tenants across
                  the US.
                </div>
                <button
                  className="bg-white text-base rounded-3xl px-8 py-3 text-dark-900 transition-colors hover:text-white hover:bg-dark-900 border-dark-900"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  Let&apos;s connect
                </button>
              </div>
              <div className="flex sm:justify-end">
                <Image
                  objectPosition="center"
                  objectFit="cover"
                  className="mx-auto"
                  src={UnitImg}
                  alt="landing cover"
                />
              </div>
            </div>
          </div>

          <div className="bg-white py-10">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-20 gap-10 max-w-screen-lg mx-auto w-11/12">
              <Image
                objectPosition="center"
                objectFit="cover"
                className="mx-auto"
                src={AboutUs}
                alt="landing cover"
              />
              <div className="my-auto sm:text-left text-center">
                <div className="text-pink-900 text-2xl font-semibold">About Us</div>
                <div className="text-dark-700 py-4 font-base">
                  We are a team of renters, landlords, and agents, on a mission to digitize the
                  leasing experience and make renting less contentious for all parties. We partner
                  with multi-family, single family, co-op, and condo property owners to reduce the
                  headache and costs associated with tenant turnover, communications, renewals,
                  payments, vacancy management and more.
                </div>
                {/* <button
                  className="text-base rounded-3xl px-8 py-3 bg-lightred-300 text-white border hover:text-lightred-300 hover:bg-white border-lightred-300"
                  onClick={() => {
                    segment.trackHomeGettingStartedTap('bottom')
                    if (token) {
                      router.push('home')
                    } else {
                      router.push('/login?next=/onboarding')
                    }
                  }}
                >
                  Get Started
                </button> */}
              </div>
            </div>
          </div>
          <Publications />
          {/* <div className="bg-pink-900 py-10">
            <div className="sm:gap-20 gap-10 max-w-screen-lg mx-auto w-11/12 text-center">
              <div className="text-white text-4xl font-semibold">
                Start connecting with residents
              </div>
              <div className="text-white font-base py-4">
                Claim your property profiles, respond to reviews, and start signing leases.
              </div>
              <button
                className="bg-white text-base rounded-3xl px-8 py-3 text-dark-900 transition-colors hover:text-white hover:bg-dark-900 border-dark-900 my-4"
                onClick={() => {
                  segment.trackHomeGettingStartedTap('bottom')
                  if (token) {
                    router.push('home')
                  } else {
                    router.push('/login?next=/onboarding')
                  }
                }}
              >
                Get Started
              </button>
            </div>
          </div> */}
          {showModal && (
            <LetsConnectModal
              onClose={() => {
                setShowModal(false)
              }}
            />
          )}
        </>
      </Layout>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/20854162.js"
      />
    </>
  )
}

export default Home
