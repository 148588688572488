import { useEffect, useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input/input'
import OISelect from '@components/generic/Select'
import { useAppSelector, useThunkDispatch } from '@redux/hooks'
import { landingRequestFormSubmit } from 'src/data/Landing'
import { submitLandingForm } from '@redux/actions'
import ClipLoader from 'react-spinners/ClipLoader'
import { v4 as uuidv4 } from 'uuid'
import getConfig from 'next/config'
import axios from 'axios'

const {
  publicRuntimeConfig: { HUBSPOT_TOKEN },
} = getConfig()

type props = {
  onClose: () => void
}

const LetsConnectModal = ({ onClose }: props) => {
  const { cities } = useAppSelector((state) => state.currentLanding)
  const dispatch = useThunkDispatch()
  const [showError, setShowError] = useState(false)
  const [phoneError, setShowPhoneError] = useState(false)
  const [emailError, setShowEmailError] = useState(false)
  const [buttonLoad, setButtonLoad] = useState(false)
  const [submissionSuccess, setSubmissionSuccess] = useState(false)
  const [formData, setFormData] = useState({
    name: null,
    email: null,
    phone: null,
    num_units: null,
    cities: [],
  })

  const formId = uuidv4()
  const formClass = uuidv4()

  const postToHubSpot = async (body: any) => {
    const url = `https://api.hubapi.com/crm/v3/objects/contacts`;


    try {
      const response = await axios.post(
        'https://api.hubapi.com/crm/v3/objects/contacts',
        body, // This is the request body, not nested inside another object
        {
          headers: {
            'Authorization': `Bearer ${HUBSPOT_TOKEN}`,
            'Content-Type': 'application/json',
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error posting to HubSpot:', error);
      throw error;
    }
  

    // const options: RequestInit = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     "Authorization": `Bearer ${HUBSPOT_TOKEN}`,
    //   },
    //   body: JSON.stringify(body),
    // };

    // try {
    //   const response = await fetch(url, options);

    //   if (!response.ok) {
    //     throw new Error(`Error: ${response.status} ${response.statusText}`);
    //   }

    //   const data = await response.json();
    //   return data;
    // } catch (error) {
    //   console.error('Error posting to HubSpot:', error);
    //   throw error;
    // }
  };

  async function submitRequest() {
    setShowError(true)
    if (
      formData.name &&
      formData.email &&
      formData.phone &&
      formData.cities.length > 0 &&
      formData.num_units
    ) {
      !isPossiblePhoneNumber(formData.phone) && setShowPhoneError(true)
      !validateEmail(formData.email) && setShowEmailError(true)
      if (isPossiblePhoneNumber(formData.phone) && validateEmail(formData.email)) {
        try {
          setButtonLoad(true)
          const data = {
            full_name: formData.name,
            email: formData.email,
            phone: formData.phone,
            cities: formData.cities,
            number_of_units: formData.num_units
          }
          await postToHubSpot({"properties": data})
          setButtonLoad(false)
          setSubmissionSuccess(true)
        } catch {
          setButtonLoad(false)
        }finally{
          setSubmissionSuccess(true)
        }
      }
    }
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  useEffect(() => {
    if (phoneError) {
      setTimeout(() => {
        setShowPhoneError(false)
      }, 5000)
    }
    if (emailError) {
      setTimeout(() => {
        setShowEmailError(false)
      }, 5000)
    }
  }, [phoneError, emailError])

  return (
    <div className="fixed bg-black bg-opacity-80 top-0 bottom-0 left-0 z-50 w-full h-screen">
      <div
        className={`slide-up relative mx-auto sm:my-20 max-w-[417px] w-full bg-white 
        sm:rounded-3xl h-full sm:max-h-[calc(100vh_-_250px)] ${
          submissionSuccess && 'border-l-8 border-green-400 h-fit !rounded'
        }`}
      >
        {submissionSuccess ? (
          <div className="py-6 px-6">
            <div className="text-dark-900 text-xl font-semibold">Your Request Was Submitted</div>
            <div className="text-dark-900 py-2">A member of our team will reach out shortly.</div>
            <div className="text-right pt-4">
              <button
                className="bg-white border text-sm rounded-3xl px-4 py-1 text-dark-900 transition-colors hover:text-white hover:bg-dark-900 border-light-40"
                onClick={onClose}
              >
                Dismiss
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="bg-light-10 flex justify-between px-8 py-7 rounded-t-3xl">
              <div className="text-dark-900 text-xl font-semibold">Let&apos;s connect</div>
              {!buttonLoad && (
                <div onClick={onClose} className="hover:cursor-pointer">
                  <AiOutlineClose color={'#E23668'} size={20} />
                </div>
              )}
            </div>

            <form
              onSubmit={submitRequest}
              action="javascript:void(0);"
              id='manager-landing-form'
              className={`px-8 pt-4 pb-8 bg-white sm:rounded-b-3xl overflow-y-auto sm:max-h-full max-h-[calc(100vh_-_145px)]`}
            >
              <div className="text-pink-900 font-semibold text-xl">Personal Information</div>
              <div className="w-full flex flex-wrap pt-2 relative">
                <label className="font-semibold text-left text-dark-900">
                  Name<span className="text-red-400">*</span>
                </label>
                <input
                  required
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className={`w-full py-2 px-6 mt-2 mb-5 rounded-3xl border border-light-40 
                  text-mid-300 focus:border-pink-900 focus:outline-none 
                  ${showError && !formData.name && '!border-red-400 !focus:border-red-400'}`}
                  placeholder="Enter your first name & last name"
                  disabled={buttonLoad}
                />
                {showError && !formData.name && (
                  <div className="text-red-400 pt-0.5 text-xs absolute bottom-0">
                    Please enter your name
                  </div>
                )}
              </div>
              <div className="w-full flex flex-wrap pt-2 relative">
                <label className="font-semibold text-left text-dark-900">
                  Email<span className="text-red-400">*</span>
                </label>
                <input
                  required
                  value={formData.email}
                  type="email"
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className={`w-full py-2 px-6 mt-2 mb-5 rounded-3xl border border-light-40 
                  text-mid-300 focus:border-pink-900 focus:outline-none
                  ${
                    ((showError && !formData.email) || emailError) &&
                    '!border-red-400 !focus:border-red-400'
                  }`}
                  placeholder="Enter your email"
                  disabled={buttonLoad}
                />
                {((showError && !formData.email) || emailError) && (
                  <div className="text-red-400 pt-0.5 text-xs absolute bottom-0">
                    {emailError ? 'Please enter a valid email' : 'Please enter your email'}
                  </div>
                )}
              </div>
              <div className="w-full flex flex-wrap pt-2 relative">
                <label className="font-semibold text-left text-dark-900">
                  Phone<span className="text-red-400">*</span>
                </label>
                <PhoneInput
                  placeholder="(+1) - -"
                  defaultCountry="US"
                  withCountryCallingCode
                  useNationalFormatForDefaultCountryValue
                  international
                  disabled={buttonLoad}
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e })}
                  className={`w-full py-2 px-6 mt-2 mb-5 rounded-3xl border border-light-40 
                  text-mid-300 focus:border-pink-900 focus:outline-none
                  ${
                    ((showError && !formData.phone) || phoneError) &&
                    '!border-red-400 !focus:border-red-400'
                  }`}
                />
                {((showError && !formData.phone) || phoneError) && (
                  <div className="text-red-400 pt-0.5 text-xs absolute bottom-0">
                    {phoneError
                      ? 'Please enter a valid phone number'
                      : 'Please enter your phone number'}
                  </div>
                )}
              </div>
              <div className="text-pink-900 font-semibold text-xl pt-4">Your Portfolio</div>
              <div className="h-0 overflow-hidden">
                <label htmlFor="city">City</label>
                <input
                  className="text-transparent"
                  name="cities"
                  value={formData.cities.join(',')}
                />
              </div>
              <div className="w-full flex flex-wrap pt-2 relative pb-5">
                <label className="font-semibold text-left text-dark-900 pb-2">
                  City<span className="text-red-400">*</span>
                </label>
                {cities && (
                  <OISelect
                    isMulti
                    closeMenuOnSelect={false}
                    options={cities}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.name}
                    onChange={(option) => {
                      setFormData({ ...formData, cities: option.map((x) => x.name) })
                    }}
                    customHeadCss={`focus-within:border-pink-900 focus:outline-none 
                      ${formData.cities.length > 1 && '!rounded-2xl'} 
                      ${
                        showError &&
                        formData.cities.length === 0 &&
                        '!border-red-400 !focus:border-red-400'
                      }`}
                    isDisabled={buttonLoad}
                    captureMenuScroll={false}
                  />
                )}
                {showError && formData.cities.length === 0 && (
                  <div className="text-red-400 pt-1 text-xs absolute bottom-0">
                    Please select a city
                  </div>
                )}
              </div>
              <div className="w-full flex flex-wrap pt-4 relative">
                <label className="font-semibold text-left text-dark-900">
                  Number of Units in Portfolio<span className="text-red-400">*</span>
                </label>
                <input
                  name="num_units"
                  type={'number'}
                  value={formData.num_units}
                  onChange={(e) => {
                    setFormData({ ...formData, num_units: e.target.value })
                  }}
                  className={`w-full py-2 px-6 mt-2 mb-5 rounded-3xl border border-light-40 
                  text-mid-300 focus:border-pink-900 focus:outline-none
                  ${showError && !formData.num_units && '!border-red-400 !focus:border-red-400'}`}
                  placeholder="Exact or estimated number of units"
                  min="0"
                  disabled={buttonLoad}
                />
                {showError && !formData.num_units && (
                  <div className="text-red-400 pt-0.5 text-xs absolute bottom-0">
                    Please enter number of units
                  </div>
                )}
              </div>
              <div className="text-right pt-4">
                <button
                  type="submit"
                  disabled={buttonLoad}
                  className="focus:outline-none inline-flex text-sm rounded-3xl px-4 py-2 bg-lightred-300 text-white border border-lightred-300 border-lightred-300"
                >
                  {buttonLoad ? <ClipLoader size={18} color="white" /> : 'Submit your request'}
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  )
}

export default LetsConnectModal
